
.faq #main {
	
	.faq-intro {
		margin: 20px 0 10px;
	}
	
	.faq-sections {
		text-align: left;
		a {
			font-size: 90%;
			color: @gray-dark;
			line-height: 1.8;
		}
	}
	
	#faq-tabs {
		margin: 20px 0;
		.nav {
			li {
				a {
					text-decoration: none;
					font-weight: bold;
					font-size: @font-size-h3;
					border-radius: 2px 2px 0 0;
					background-color: #000;
					color: #fff;
					margin-right: 5px;
					.badge {
						background: @theme-color;
					}
				}
				> a:hover,
				&.active > a,
				&.active > a:focus {
					color: @nav-tabs-active-link-hover-color;
					background-color: @nav-tabs-active-link-hover-bg;
					border: 1px solid @nav-tabs-active-link-hover-border-color;
					border-bottom-color: #fff;
				}
			}
		}
		h2,
		h4 {
			margin-top: 30px;
			margin-bottom: 20px;
		}
		a.label {
			text-decoration: none;
			&:hover {
				background-color: @theme-color;
			}
		}
		.section {
			margin-top: 20px;
			padding-top: 2px;
			border-top: 1px solid @gray-bg;
			font-size: @font-size-small;
			color: @gray-dark;
			a {
				color: @gray;
			}
		}
	}
	
	#faq-search {
		@import "../../../common/faq_search";
		h3 {
			margin: 30px 0 20px;
		}
	}
	
	@media(max-width: 760px) {
		#faq-tabs {
			.nav {
				li {
					a {
						font-size: @font-size-h5;
						padding: 7px 12px;
					}
				}
			}
		}
	}
	@media(max-width: 560px) {
		#faq-tabs {
			.nav {
				border: none;
				li {
					float: none;
					margin-bottom: 2px;
					margin-right: 0;
					a {
						border-radius: 2px;
						font-size: @font-size-h4;
						padding: 7px 12px;
					}
					> a:hover,
					&.active > a,
					&.active > a:focus {
						border-bottom: 1px solid #000;
					}
				}
			}
		}
	}
	
}

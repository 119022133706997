
a {
	text-decoration: underline;
}
p {
	margin-bottom: 12px;
}
hr {
	border-color: #666;
}
label {
	font-weight: normal;
}

.form-control {
	border-radius: 0;
}
.form-group {
	border: 1px solid @gray-bg;
	border-bottom-width: 0;
	padding: 5px 0;
	margin-bottom: 0;
	&:last-child {
		border-bottom-width: 1px;
	}
}
.form-horizontal {
	.control-label {
		text-align: left;
	}
	fieldset {
		border: 1px solid @gray-bg;
		border-bottom-width: 0;
		padding: 5px 15px;
		margin: 0 -15px;
		.form-group {
			border: 0;
		}
	}
}
.form-error-list {
	background: lighten(@theme-color, 50%);
	padding: 5px 10px 5px 20px;
}

#header {
	background: @theme-color;
	color: #fff;
	border: 1px solid #000;
	position: relative;
	a.logo {
		display: block;
		text-decoration: none;
		background: #000;
		text-align: center;
		img {
			height: 40px;
		}
	}
	h1 {
		text-align: center;
		margin: 0;
		padding: 5px 0;
		font-size: 20px;
		line-height: 20px;
		text-transform: uppercase;
		&.long {
			font-size: 12px;
		}
		&.medium {
			font-size: 16px;
		}
	}
	#language-switcher {
		cursor: pointer;
		margin: 5px 5px 0;
		position: absolute;
		right: 0;
		top: 0;
	}
}

#language-select {
	overflow: hidden;
	clear: both;
	background: #fff;
	border: 1px solid #000;
	border-top: 0;
	a {
		padding-left: 2px;
		display: block;
		float: left;
		width: 33.3333%;
		text-decoration: none;
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		.flag {
			vertical-align: middle;
		}
	}
}

#main {
	background: #fff;
	border: 1px solid #000;
	border-top: 0;
	padding: 15px;
	overflow: hidden;
	clear: both;
	text-align: justify;
	span a {
		color: inherit;
	}
	.black-box {
		background: #000;
		color: #fff;
		text-align: left;
		a {
			color: #fff;
		}
	}
	.http-status & {
		h1, h2, h3, h4 {
			text-align: center;
			margin: 1em 0;
		}
	}
	.home & {
		padding: 0;
		p {
			margin: 5px;
		}
		.black-box {
			position: relative;
			padding: 8px;
			h2 {
				margin: 0;
				font-size: 16px;
				color: #555;
				text-transform: uppercase;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			ul {
				margin: 0;
				padding: 0 0 14px;
				border-bottom: 2px solid #fff;
				li {
					padding: 14px 0;
					font-size: 16px;
					list-style: none;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
			&:last-child {
				ul {
					border-bottom: 0;
				}
			}
		}
	}
	.content &,
	.takedown &,
	.authority-contact &,
	.contact & {
		h1, h2, h3 {
			text-align: center;
		}
		h2 {
			font-weight: normal;
			em {
				font-weight: bold;
			}
		}
		h3 {
			margin: 20px 0;
		}
		h4 {
			margin: 15px 0;
		}
		h5, h6 {
			font-weight: normal;
			margin: 15px 0;
		}
		.overflowable-table {
			margin-bottom: 12px;
			table {
				margin-bottom: 0;
				&.overflowable-table-fixed-first-row {
					th {
						border-bottom: 0;
					}
				}
			}
		}
		table {
			width: 100%;
			margin-bottom: 12px;
			th, td {
				border: 1px solid #eee;
				padding: 4px 10px;
				text-align: center;
			}
			thead {
				th {
					background: #000;
					border-top: #000;
					color:  #fff;
					&:first-child {
						border-left: #000;
					}
					&:last-child {
						border-right: #000;
					}
				}
			}
			tbody {
				tr{
					td {
						background: #fff;
					}
					&:nth-child(2n) {
						td {
							background: #f0f0f0;
						}
					}
					&:hover,
					&.focused {
						td {
							background: #ddd;
						}
					}
				}
			}
		}
	}
	.content &,
	.takedown &,
	.authority-contact &,
	.contact &,
	.faq & {
		.black-box {
			margin: 15px -5px -5px;
			padding: 5px 10px;
			text-align: center;
			font-size: 28px;
			font-weight: bold;
			p {
				margin: 0;
			}
			&.small {
				font-size: 20px;
				padding: 15px 10px;
			}
			&.medium {
				font-size: 24px;
				padding: 15px 10px;
			}
		}
	}
	.content.faq & {
		> h4 {
			margin: 25px 0 5px;
			font-size: 14px;
		}
	}
	.takedown & {
		h3.bg-danger,
		h4.bg-primary {
			padding: 10px;
		}
		#takedown_personal_data {
			.form-group {
				margin-bottom: 7px;
				label {
					font-weight: bold;
				}
			}
		}
		#takedown_personal_data,
		#takedown_signature,
		#takedown_account_termination {
			border-bottom: 1px solid @gray-bg;
		}
		#takedown_statement_copyright,
		#takedown_statement_authorized,
		#takedown_account_termination {
			.fieldset-header {
				margin-bottom: 0;
			}
		}
		.form-field-takedown_statement_copyright_confirm,
		.form-field-takedown_statement_authorized_confirm,
		.form-field-takedown_statement_belief_confirm,
		.form-field-takedown_account_termination_repeat_infringer {
			.content {
				margin-left: 0;
				padding-top: 0;
				width: 100%;
			}
		}
	}
}

#footer {
	padding: 18px 0;
	font-size: 16px;
	text-align: center;
	a {
		font-weight: bold;
		text-transform: uppercase;
	}
}

@media(min-width: 480px) {
	#header {
		h1 {
			padding: 0;
			font-size: 28px;
			line-height: 40px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&.long {
				font-size: 13px;
			}
			&.medium {
				font-size: 20px;
			}
		}
	}
	#main {
		.home & {
			.black-box {
				h2 {
					font-size: 20px;
					right: 8px;
					position: absolute;
				}
			}
		}
	}
	#language-select {
		a {
			width: 25%;
		}
	}
}

@media(min-width: 640px) {
	#header {
		a.logo {
			float: left;
		}
		a.logo-red {
			padding-right: 8px;
		}
		#language-switcher {
			float: right;
			position: static;
			right: auto;
			top: auto;
		}
	}
	#header,
	#language-select,
	#main,
	#footer {
		max-width: 930px;
		margin: 0 auto;
	}
	#language-select {
		a {
			width: 20%;
		}
	}
}



.overflowable-table {
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 1;
	
	table {
		position: relative;
		z-index: 1;
	}
	
	.overflowable-table-container {
		overflow: auto;
		width: 100%;
	}
	
	.overflowable-table-gotoleft,
	.overflowable-table-gotoright {
		bottom: 0;
		cursor: pointer;
		display: none;
		left: 0;
		position: absolute;
		top: 0;
		width: 40px;
		z-index: 4;
		.arrow {
			background: rgba(0,0,0,0.35);
			border-radius: 3px;
			color: white;
			display: block;
			font-size: 25px;
			font-weight: bold;
			font-weight: bolder;
			height: 40px;
			line-height: 34px;
			left: 0;
			padding: 0;
			position: absolute;
			text-align: center;
			.text-border-2px-with-depth(#444);
			top: 0;
			transition: 0.2s width;
			width: 40px;
			z-index: 2;
		}
		&:hover {
			.icon-f {
				width: 34px;
			}
		}
	}
	
	.overflowable-table-gotoright {
		&,
		&:before,
		.arrow {
			left: auto;
			right: 0;
		}
	}
	
	.overflowable-table-fixed-first-row {
		margin-bottom: 0;
		position: relative;
		z-index: 3;
		th {
			border-bottom: 0;
		}
		&.is_over {
			box-shadow: 0 2px 2px rgba(0,0,0,0.5);
		}
	}
	.overflowable-table-fixed-first-col {
		left: 0;
		position: absolute;
		top: 0;
		z-index: 2;
		&.is_over {
			box-shadow: 2px 0 2px rgba(0,0,0,0.2);
		}
	}
}
